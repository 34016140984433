import { CaseProjection } from '../../../CaseProjection';
import { getCaseTopLevelsAutoCountFromRealBuiltSurface } from './getCaseTopLevelsAutoCountFromRealBuiltSurface';
import { hasCaseProjectionDrawnFloorSpaceFeature } from '../../has/hasCaseProjectionDrawnFloorSpaceFeature';

export const setCaseProjectedTopLevelsCount = (caseProjection: CaseProjection): CaseProjection => {
  const topLevelsCountFromRbs = getCaseTopLevelsAutoCountFromRealBuiltSurface(
    caseProjection.projectedSurfaces.realBuiltSurface.value
  );

  console.log("TEEEEEEEEEEEST");
  console.log(caseProjection);

  const calculatedTopLevelCount =
    hasCaseProjectionDrawnFloorSpaceFeature(caseProjection) &&
    caseProjection.levels.length > topLevelsCountFromRbs
      ? caseProjection.levels.length
      : topLevelsCountFromRbs;
  return {
    ...caseProjection,
    projectedTopLevelsCount: caseProjection.topLevelsCount || calculatedTopLevelCount
  };
};
